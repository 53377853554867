import React from 'react'
import { graphql } from 'gatsby'
import {
  H1,
  Row,
  Flex,
  Column,
  Button,
  Section,
  Wrapper,
  LightSection,
  SectionTitle,
  Billboard,
  SEO,
} from '@renderbus/common/components'
import {
  BannerContainer,
  SectionCard,
  CaseCard,
  Software,
  RegisteredContainer,
  RegisteredTitle,
} from './rendering-cluster.atom'
import { color } from '@renderbus/common/theme'

import Layout from '../molecules/layout'

import fast from '../images/rendering-cluster/fast.png'
import performance from '../images/rendering-cluster/performance.png'
import compatible from '../images/rendering-cluster/compatible.png'
import dispatch from '../images/rendering-cluster/dispatch.png'
import manage from '../images/rendering-cluster/manage.png'
import easy from '../images/rendering-cluster/easy.png'
import plan from '../images/rendering-cluster/plan.png'
import case01 from '../images/rendering-cluster/case01.png'
import case02 from '../images/rendering-cluster/case02.png'
import case03 from '../images/rendering-cluster/case03.png'
import case04 from '../images/rendering-cluster/case04.png'
import zz01 from '../images/rendering-cluster/zz01.png'
import zz02 from '../images/rendering-cluster/zz02.png'
import zz03 from '../images/rendering-cluster/zz03.png'
import zz04 from '../images/rendering-cluster/zz04.png'
import zz05 from '../images/rendering-cluster/zz05.png'
import zz06 from '../images/rendering-cluster/zz06.png'
const sectionList = [
  {
    img: fast,
    title: '极速调度',
    description_1: '服务器能同时调度超过10000节点机',
    description_2: '随时可用，极速出图',
  },
  {
    img: performance,
    title: '高容错性',
    description_1: '客户端支持断开重连且不影响任务执行',
    description_2: '服务器重启不影响客户端任务进行',
  },
  {
    img: compatible,
    title: '完美兼容性',
    description_1: '服务器和客户端支持多平台',
    description_2: 'Windows、centos和Ubuntu，支持各类主流CG制作软件和插件',
  },
  {
    img: dispatch,
    title: '灵活调度',
    description_1: '按需设置排队和节点机排队分配机制节点机与',
    description_2: '任务之间根据需求进行任意匹配',
  },
  {
    img: manage,
    title: '弹性管理',
    description_1: '支持主动链接和被动链接两种模式',
    description_2: '同一服务器支持同一IP的多个客户端',
    description_3: '同一客户端可链接多个相同或不同的服务器',
  },
  {
    img: easy,
    title: '极速调度',
    description_1: '服务器能同时调度超过10000节点机',
    description_2: '随时可用，极速出图',
  },
]

const caseList = [
  {
    img: case01,
    title: '深圳职业技术学院动画学院',
    description:
      '用途：提供建筑及影视动画类教学渲染环境。校园公共服务平台，为全校提供公共网站、应用系统的运行服务。内部业务平台，为学院内多媒体教学提供云平台服务。',
  },
  {
    img: case02,
    title: '内蒙古电子信息技术职业技术学院',
    description: '用途：建立一套高效的建筑及动画类影视教学互动平台。',
  },
  {
    img: case03,
    title: '法国Stimergy SA公司',
    description: '用途：为法国本土及其合作伙伴提供一个公有云的渲染平台。',
  },
  {
    img: case04,
    title: '丝路视觉',
    description: '用途：为丝路集团所有制作人员提供独立的渲染平台和丝路教育提供云桌面。',
  },
]

const SoftwareList = [
  {
    img: zz01,
    title1: '知识产权管理',
    title2: '体系认证',
  },
  {
    img: zz02,
    title1: '国家高新',
    title2: '企业认证',
  },
  {
    img: zz03,
    title1: '软件企业',
    title2: '证书',
  },
  {
    img: zz04,
    title1: '中国云渲染平台',
    title2: '领军企业奖',
  },
  {
    img: zz05,
    title1: 'ISO27001',
    title2: '安全认证',
  },
  {
    img: zz06,
    title1: '奥斯卡获奖',
    title2: '作品',
  },
]

class RenderingCluster extends React.PureComponent {
  render() {
    const {
      location,
      data,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='渲染集群管理,集群渲染搭建方案,如何搭建渲染农场-Renderbus'
          keywords='渲染集群,搭建渲染农场'
          description='Renderbus集群渲染管理服务,提供搭建渲染农场解决方案,帮助企业构建强大稳定的渲染集群,具有极速调度、高容错性、多平台兼容、便携管理等特点,为企业腾飞助力!'
          sharePostSlug='rendering-cluster.html'
        />
        <BannerContainer>
          <Flex direction='column' style={{ height: '100%' }}>
            <H1>
              渲染<span>解决方案</span>
            </H1>
            <p className='description'>
              搭建属于自己的渲染集群，调用本地渲染农场更加方便快捷无缝对接内部制作，无传输压力，输出更稳定
            </p>
            <p className='contact'>联系我们: 186 1269 8101（冯先生）</p>
          </Flex>
          <Billboard fluid={data.banner.childImageSharp.fluid} />
        </BannerContainer>
        <Section>
          <Wrapper>
            <SectionTitle>构建强大稳定的渲染集群</SectionTitle>
            <Row spacing={1}>
              {sectionList.map((p, i) => (
                <Column xs='4' lg='4' key={i}>
                  <SectionCard style={{ background: `url(${p.img})  no-repeat left top` }}>
                    <h1 className='title'>{p.title}</h1>
                    <p>{p.description_1}</p>
                    <p>{p.description_2}</p>
                    <p>{p.description_3}</p>
                  </SectionCard>
                </Column>
              ))}
            </Row>
          </Wrapper>
        </Section>
        <LightSection>
          <Wrapper>
            <SectionTitle>渲染集群搭建方案</SectionTitle>
            <Row spacing={1} alignItems='stretch'>
              <Column xs='12' lg='6'>
                <p style={{ height: '80px', marginTop: '50px', color: color.prompt }}>
                  渲染平台架构：
                </p>
                <p style={{ maxWidth: '500px', color: color.prompt }}>
                  底层技术源自瑞云渲染为奥斯卡获奖影片所搭建的平台架构，可轻松快捷搭建成熟稳定的集群渲染环境，同时具有良好的扩展性，随时实现渲染节点的海量调度和管理，支持网页、插件和客户端等提交方式。
                </p>
              </Column>
              <Column xs='12' lg='6'>
                <img
                  style={{ height: '100%', width: '100%', display: 'block' }}
                  src={plan}
                  alt=''
                />
              </Column>
            </Row>
          </Wrapper>
        </LightSection>
        <Section>
          <Wrapper>
            <SectionTitle>集群渲染应用案例</SectionTitle>
            <Row spacing={1}>
              {caseList.map((p, i) => (
                <Column xs='6' lg='3' key={i}>
                  <CaseCard>
                    <img src={p.img} alt={p.title} />
                    <h2 className='title'>{p.title}</h2>
                    <p className='description'>{p.description}</p>
                  </CaseCard>
                </Column>
              ))}
            </Row>
          </Wrapper>
        </Section>
        <LightSection>
          <Wrapper>
            <SectionTitle>资质</SectionTitle>
            <Row spacing={1} alignItems='stretch'>
              {SoftwareList.map((p, i) => (
                <Column xs='4' lg='2' key={i}>
                  <Software>
                    <img src={p.img} alt={p.title1} />
                    <p>{p.title1}</p>
                    <p>{p.title2}</p>
                  </Software>
                </Column>
              ))}
            </Row>
          </Wrapper>
        </LightSection>
        <RegisteredContainer>
          <Wrapper>
            <RegisteredTitle>欢迎免费体验瑞云渲染集群</RegisteredTitle>
            <Button variant='outlined'>立即体验</Button>
          </Wrapper>
        </RegisteredContainer>
      </Layout>
    )
  }
}

export default RenderingCluster

export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/rendering-cluster/jqbanner_bg.png/" }) {
      ...fluidImage
    }
  }
`

import styled from 'styled-components'
import { Media, typography, color } from '@renderbus/common/theme'

export const BannerContainer = styled.div`
  position: relative;
  padding-top: 60px;
  height: 600px;
  color: white;
  h1 {
    max-width: 600px;
    margin: 0 auto;
    font-size: 72px;
    font-weight: normal;
    color: ${color.primary};
    span {
      color: white;
    }
  }
  .description {
    max-width: 524px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 1.5;
    font-size: 20px;
    text-align: center;
  }
  .contact {
    max-width: 370px;
    margin: 0 auto;
    margin-top: 150px;
    font-size: ${typography.h4};
  }
  ${Media.lessThan(Media.small)} {
    h1 {
      font-size: ${typography.h1};
    }
    .description {
      margin-top: 5px;
    }
    .contact {
      margin-top: 30px;
    }
    p {
      font-size: ${typography.text};
    }
  }
`

export const SectionCard = styled.div`
  padding-left: 95px;
  .title {
    text-align: left;
    font-size: ${typography.h4};
    margin-top: 0;
    margin-bottom: 5px;
    color: white;
  }
  p {
    margin-bottom: 5px;
    color: ${color.prompt};
  }
  ${Media.lessThan(Media.small)} {
    padding: 0;
    padding-top: 95px;
    background-position-x: center !important;
    p {
      display: none;
    }
    .title {
      text-align: center;
    }
  }
`
export const CaseCard = styled.div`
  height: 412px;
  background-color: white;
  text-align: center;
  overflow: hidden;
  p {
    margin-bottom: 5px;
    color: ${color.prompt};
    text-align: center;
  }
  img {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }
  .title {
    max-width: 216px;
    padding: 0px;
    margin: 0 auto;
    font-size: ${typography.h4};
    font-weight: normal;
    color: ${color.input};
  }
  .description {
    max-width: 216px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ${Media.lessThan(Media.small)} {
    height: 352px;
    img {
      max-width: 100%;
    }
    .description {
      margin-top: 5px;
    }
    .contact {
      margin-top: 30px;
    }
    p {
      font-size: ${typography.textSmall};
    }
  }
`
export const Software = styled.div`
  img {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 5px;
  }
  p {
    margin: 0px;
    margin-bottom: 5px;
    color: white;
    text-align: center;
  }
`
export const RegisteredContainer = styled.div`
  padding: 40px 0;
  text-align: center;
  background: ${color.primary};
  color: white;
  @media (max-width: 600px) {
    display: none;
  }
`
export const RegisteredTitle = styled.h2`
  margin: 1rem;
  font-size: 2.25rem;
  font-weight: normal;
`
